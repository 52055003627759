div.green-avatar {
  background-color: #28a745;
}

div.blue-avatar {
  background-color: #007bff;
}

div.yellow-avatar {
  background-color: #fcbd0b;
}

div.red-avatar {
  background-color: #dc3545;
}

div.dashboard-toggler {
  display: inline-block;
  border-radius: 50px;
  background-color: #000000;
  padding: 5px;
  margin-top: 15px;
  width: 160px;
}

div.dashboard-toggler button {
  border-radius: 50px;
  line-height: 0.8rem;
}

div.dashboard-toggler button .MuiButton-label {
  color: #ffffff;
}