html, body {
  overflow: hidden;
  position: relative;
  display: flex;
  flex: 1;
  height: 100%;
  background-color: #000;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.container {
  padding-left: 250px;
  display: flex;
  flex: 1 1 auto;
  background-color: #eef1f5;
  max-height: 100%;
  overflow-y: auto;
}

.dashboard-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 2rem 4rem;
  width: 100%;
  overflow-x: hidden;
}

h2 {
  text-transform: uppercase;
  font-weight: 700;
}

a {
  text-decoration: none;
  color: inherit;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: inherit;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #cb0000;
  border-radius: 20px;
  border: 0;
}
*::-webkit-scrollbar-corner {
  background: inherit;
}

.logo {
  max-height: 32px;
}

a.drawer-link {
  padding: 14px 16px;
}
a.drawer-list {
  border-radius: 6px;
  margin: 12px;
  width: calc(100% - 24px);
}
a.drawer-list-active,
a.drawer-list-active:hover {
  background-color: #000;
  color: #ffffff;
}

.breadcrumbs ol li a,
.breadcrumbs ol li p {
  font-size: 0.75rem;
}

.breadcrumbs ol li a {
  color: #000;
}

.breadcrumbs ol li p {
  color: #72808c;
}

.subtitle {
  color: #cb0000;
  font-weight: 700 !important;
}
.subtitle.back {
  color: #000000;
  font-weight: 700 !important;
}

.form-icon {
  color: #b6c3ce;
}

.header-icon {
  color: #cb0000;
}

button.dialog-close-button {
  float: right;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}