.stepper-icon {
  background-color: #ccc;
  z-index: 1;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.stepper-icon.active {
  background-color: #cb0000;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,.25);
}

.stepper-icon.completed {
  background-color: #000000;
  cursor: pointer;
}

.thumbnail-slider {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  border: 1px solid #F1f1f1;
  background-color: #F9f9f9;
  overflow-x: auto;
}

.thumbnail-slider .image {
  position: relative;
  display: inline-block;
  height: 150px;
}

.thumbnail-slider .image img {
  max-height: 140px;
  padding: 5px;
}

.thumbnail-slider .image .drop {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  background-color: #cb0000;
  color: #ffffff;
}
.thumbnail-slider .image .MuiFormControlLabel-label {
  font-size: 10px;
}

.dropzone > div {
  height: 100px;
  min-height: 100px;
}
.dropzone > div .MuiTypography-h5 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 3px;
  margin-top: 16px;
}
.dropzone button.MuiDropzonePreviewList-removeButton {
  display: none;
}

.list-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
