.logo {
  margin: 10px;
}

.marketplace-container {
  display: flex;
  flex: 1 1 auto;
  background-color: #eef1f5;
  max-height: 100%;
  overflow-y: auto;
}

.price {
  color: #cb0000;
}

.image-viewer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  border: 1px solid #eef1f5;
  background-color: #f9f9f9;
}

.image-viewer .image-viewer-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.image-viewer-navigator {
  position: absolute;
  bottom: -45px;
  margin: 0 auto;
}

.image-viewer-navigator button {
  padding: 5px;
}

.tag {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1;
}

.emphasis {
  position: absolute;
  top: 210px;
  left: 0px;
  padding: 7px;
  background-color: #cb0000;
  color: #ffffff;
  font-size: 1.2rem;
  z-index: 1;
}

.image-slider {
  display: flex;
  flex-direction: row;
  height: 150px;
  width: 100%;
  padding-bottom: 15px;
  overflow-x: auto;
}

.image-slider img {
  cursor: pointer;
  max-height: 100%;
  padding-left: 15px;
}