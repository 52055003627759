.machine-length {
  cursor: pointer;
  border: 1px solid #cb0000;
  padding: 8px 24px;
  border-radius: 6px;
  white-space: nowrap;
}

.machine-length span {
  padding-left: 5px;
}

.machine-length svg {
  color: #cb0000;
}