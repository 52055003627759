.task-header {
  border-radius: 0 !important;
  padding: 3px 15px;
  color: #ffffff !important;
}

.task-header.pending {
  background-color: #7a7a7a;
}

.task-header.execution {
  background-color: #007bff;
}

.task-header.done {
  background-color: #28a745;
}

.task-header span {
  font-weight: 400;
}

.task-container {
  min-height: 20px;
  background-color: #eef1f5;
}

.task-container-droparea {
  border: 3px dashed #e1e1e1;
  min-height: 50px;
  background-color: #eef1f5;
  padding: 10px;
  padding-bottom: 0px;
}